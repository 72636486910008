import * as React from "react";
import CaseStudyPageTwo from "../components/caseStud/CaseStudyPageTwo";
import HeaderCompo from "../components/header/Header";
import {Layout} from "../components/Layout";
import serviceBg from "../images/serviceBg.png";
import SEO from "../components/seo";

const casestudiespage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Case Studies | Zector"
          description="Zector's five-step process for application development."
        />
        <HeaderCompo
          headerTitle="Case Studies"
          headerBtn="Tell us about your project"
          headerbg={serviceBg}
        />
        <CaseStudyPageTwo />
      </Layout>
    </>
  );
};

export default casestudiespage;
