import React from "react";
import * as styles from "./cases.module.css";
import Check from "../../images/check.png";
import Case1 from "../../images/case1.png";
import { Link } from "gatsby";
const CaseStudyPageTwo = () => {
  return (
    <div>
      <div className={styles.cs_header}>
        <h1>Enjoy Coin </h1>
      </div>
      <div className={styles.caseStudyy}>
        <div className={styles.challenge_head}>
          <img src={Case1} alt="" />
          <h1>Challenge</h1>
        </div>

        <p>
          Enjoy Coin's founders came to Zector with an idea to create a mobile
          application that provides a better method for services workers to
          receive their tips. There was no code written just a thought that the
          Enjoy Coin team was looking to bring to reality.
          <br />
          <br />
          The Challenging part was that the EnjoyCoin team had a vision of what
          they wanted, but the specifics were not present.
          <br />
          <br />
          So we agree to start with:
          <br />
          <br />
        </p>
        <ul>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              {" "}
              Outlining Enjoy Coin's key goals of the project and expectations.
            </li>
          </div>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              {" "}
              Creating user personas, and other specifics catered to how the
              application will function.
            </li>
          </div>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              Focusing on the UI/UX design for each aspect of the project.
            </li>
          </div>
        </ul>
        <div className={styles.lmWrap}>
          <Link to="/enjoy-coin-case-study" className={styles.learnMore}>
            Learn More
          </Link>
        </div>
      </div>

      <div className={styles.letsbuild_main}>
        <div className={styles.letsbuild_head}>
          <h1>
            Let's build something <span>together</span>
          </h1>
        </div>
        <div className={styles.letsbuild_btn}>
          <Link to="/contact" style={{ textDecoration: `none` }}>
            <span style={{ textDecoration: `none` }}>
              {" "}
              Tell us about your project{" "}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyPageTwo;
